<template>
	<button class="button" :class="iconPosition" :style="style">
		<span
			v-if="button.icon"
			:class="[
				'icon-holder',
				{
					isNonMargin: !button.title
				}
			]"
		>
			<img :src="require(`@/assets/images/${button.icon}`)" alt="icon" />
		</span>
		{{ button.title }}
	</button>
</template>

<script>
export default {
	props: {
		button: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			style: {
				"--padding-x": this.button.paddingX,
				"--color": this.button.color,
				"--font-size": this.button.fontSize,
				"--border-radius": this.button.borderRadius,
				"--background-color": this.button.backgroundColor,
				"--border-color": this.button.borderColor,
				"--height": this.button.height,
				"--width": this.button.width
			}
		};
	},
	computed: {
		iconPosition() {
			if (this.button.iconPosition === "right") {
				return "icon-right";
			} else if (this.button.iconPosition === "left") {
				return "icon-left";
			} else {
				return null;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding-left: var(--padding-x);
	padding-right: var(--padding-x);
	min-height: var(--height);
	min-width: var(--width);
	font-weight: 500;
	font-size: var(--font-size);
	color: var(--color);
	background: var(--background-color);
	border: solid 1px var(--border-color);
	border-radius: var(--border-radius);
	cursor: pointer;

	.icon-holder {
		display: inline-flex;
	}

	&.icon-absolute-left {
		padding-left: 48px;
		padding-right: 48px;

		.icon-holder {
			position: absolute;
			top: 50%;
			left: 24px;
			transform: translateY(-50%);
		}
	}

	&.icon-left {
		.icon-holder {
			margin-right: 12px;
			&.isNonMargin {
				margin: 0;
			}
		}
	}

	&.icon-right {
		.icon-holder {
			margin-left: 12px;
			&.isNonMargin {
				margin: 0;
			}
		}
	}
}
</style>
